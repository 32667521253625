import React, { SVGProps } from 'react';

export const Email = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M3.00985 7.30025L11.9576 11.4901C11.9845 11.5027 12.0155 11.5027 12.0424 11.4901L20.9902 7.30026C20.8899 6.28947 20.0372 5.5 19 5.5H5C3.96284 5.5 3.11008 6.28947 3.00985 7.30025Z"
        fill="currentColor"
      />
      <path
        d="M21 9.28321L12.8057 13.1202C12.2952 13.3593 11.7048 13.3593 11.1943 13.1202L3 9.28321V16.5C3 17.6046 3.89543 18.5 5 18.5H19C20.1046 18.5 21 17.6046 21 16.5V9.28321Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Email;
