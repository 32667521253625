import React, { SVGProps } from 'react';

export const Check = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M13.7287 4.27746C14.0802 4.62893 14.0802 5.19878 13.7287 5.55025L6.55647 12.7225C6.38769 12.8913 6.15877 12.9861 5.92008 12.9861C5.68138 12.9861 5.45246 12.8913 5.28368 12.7225L2.2636 9.70243C1.91213 9.35096 1.91213 8.78111 2.2636 8.42963C2.61508 8.07816 3.18492 8.07816 3.5364 8.42963L5.92008 10.8133L12.4559 4.27746C12.8074 3.92599 13.3773 3.92599 13.7287 4.27746Z"
        fill="currentcolor"
      />
    </svg>
  );
};
