import React, { SVGProps } from 'react';

export const Copy = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4035 12.4H13C14.6569 12.4 16 11.0569 16 9.40002V3C16 1.34315 14.6569 0 13 0H6.5965C4.93964 0 3.5965 1.34315 3.5965 3V3.59998H3C1.34315 3.59998 0 4.94312 0 6.59998V13C0 14.6569 1.34315 16 3 16H9.40351C11.0604 16 12.4035 14.6569 12.4035 13V12.4ZM13 1.8H6.5965C5.93375 1.8 5.3965 2.33726 5.3965 3V3.59998H9.40351C11.0604 3.59998 12.4035 4.94312 12.4035 6.59998V10.6H13C13.6628 10.6 14.2 10.0628 14.2 9.40002V3C14.2 2.33726 13.6628 1.8 13 1.8ZM3 5.39998H9.40351C10.0663 5.39998 10.6035 5.93724 10.6035 6.59998V13C10.6035 13.6627 10.0663 14.2 9.40351 14.2H3C2.33726 14.2 1.8 13.6627 1.8 13V6.59998C1.8 5.93723 2.33726 5.39998 3 5.39998Z"
        fill="currentcolor"
      />
    </svg>
  );
};
