import { SVGProps } from 'react';

export const Gitcoin = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 57 10" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.831543 5.0451C0.831543 2.04873 3.1234 0 6.01713 0C7.75845 0 9.33336 0.703992 10.3448 2.21514L8.25767 3.30327C7.69417 2.53499 6.96425 2.12545 6.04253 2.12545C4.4163 2.12545 3.27685 3.39295 3.27685 5.05754C3.27685 6.72214 4.51895 7.96423 5.96581 7.96423C7.15658 7.96423 8.01454 7.24728 8.16799 6.29964V6.28668H6.14518V4.46864H10.8186V4.87818C10.8186 8.02799 8.93626 10 5.94041 10C3.11044 10 0.831543 7.96423 0.831543 5.0451ZM18.141 9.83362V2.36861H16.0285V0.179393H22.6226V2.36913H20.5101V9.83414H18.1415L18.141 9.83362ZM12.4433 0.179393V9.83362H14.8118V0.179393H12.4433ZM43.4998 9.83362V0.179393H45.8684V9.83362H43.4998ZM57.0005 0.179393H54.6833L54.76 6.22294L49.9202 0.179393H47.9482V9.83362H50.2784L50.24 3.75171L55.0799 9.83362H57.0005V0.179393ZM28.3613 7.81079C26.8891 7.81079 25.647 6.65838 25.647 5.00675C25.647 3.4448 26.8378 2.20271 28.3873 2.20271C29.1504 2.20271 29.8165 2.49146 30.3012 2.96942C30.535 2.22292 30.8989 1.52463 31.3826 0.900477C30.5355 0.334898 29.5049 0.01297 28.3997 0.01297C25.5573 0.01297 23.2012 2.13842 23.2012 4.99379C23.2012 7.99016 25.5697 10 28.3613 10C29.4759 10 30.5143 9.68793 31.366 9.13324C30.888 8.5096 30.5278 7.80716 30.2971 7.05133C29.8051 7.52723 29.1291 7.81079 28.3613 7.81079ZM32.5371 2.17938C33.4759 0.850191 35.0679 0.01297 36.8518 0.01297C39.6817 0.01297 42.0249 2.0871 42.0249 5.01919C42.0249 7.95128 39.6688 10 36.8134 10C35.0576 10 33.4687 9.20426 32.5319 7.86834C31.9793 7.08036 31.6532 6.05703 31.6532 4.99379C31.6532 3.93054 31.9803 2.96839 32.5371 2.17938ZM34.099 5.00675C34.099 6.65838 35.3411 7.81079 36.8134 7.81079H36.8139C38.3888 7.81079 39.5796 6.60758 39.5796 5.01971C39.5796 3.43184 38.3888 2.20271 36.8393 2.20271C35.2898 2.20271 34.099 3.4448 34.099 5.00675Z"
        fill="#130C03"
      />
    </svg>
  );
};
