import React, { SVGProps } from 'react';

export const Lock = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M8.00001 8.59998C8.49706 8.59998 8.90001 9.00292 8.90001 9.49998V11.5C8.90001 11.997 8.49706 12.4 8.00001 12.4C7.50295 12.4 7.10001 11.997 7.10001 11.5V9.49998C7.10001 9.00292 7.50295 8.59998 8.00001 8.59998Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2 4.99986C13.1999 2.2385 10.9614 0 8.19999 0H7.79999C5.03861 0 2.80006 2.23864 2.79999 5V5.24963C1.74045 5.71257 1 6.76981 1 8V13C1 14.6569 2.34315 16 4 16H12C13.6569 16 15 14.6569 15 13V8C15 6.7698 14.2595 5.71255 13.2 5.24962V4.99986ZM8.19999 1.8H7.79999C6.03272 1.8 4.60006 3.23262 4.59999 4.99986H11.4C11.3999 3.23262 9.96725 1.8 8.19999 1.8ZM12 6.8H4C3.33726 6.8 2.8 7.33726 2.8 8V13C2.8 13.6627 3.33726 14.2 4 14.2H12C12.6627 14.2 13.2 13.6627 13.2 13V8C13.2 7.33726 12.6627 6.8 12 6.8Z"
        fill="currentcolor"
      />
    </svg>
  );
};
