import React, { SVGProps } from 'react';

export const Delete = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.20078 0C5.70372 0 5.30078 0.402944 5.30078 0.9C5.30078 1.39706 5.70372 1.8 6.20078 1.8H9.79922C10.2963 1.8 10.6992 1.39706 10.6992 0.9C10.6992 0.402944 10.2963 0 9.79922 0H6.20078ZM0 3.60001C0 3.10296 0.402944 2.70001 0.9 2.70001H2.70312H13.2962H15.1C15.5971 2.70001 16 3.10296 16 3.60001C16 4.09707 15.5971 4.50001 15.1 4.50001H14.2962V13C14.2962 14.6568 12.953 16 11.2962 16H4.70312C3.04627 16 1.70312 14.6568 1.70312 13V4.50001H0.9C0.402944 4.50001 0 4.09707 0 3.60001ZM3.50312 4.50001H12.4962V13C12.4962 13.6627 11.9589 14.2 11.2962 14.2H4.70312C4.04038 14.2 3.50312 13.6627 3.50312 13V4.50001ZM6.19999 12.3984C5.70293 12.3984 5.29999 11.996 5.29999 11.4997V7.19958C5.29999 6.70321 5.70293 6.30082 6.19999 6.30082C6.69704 6.30082 7.09999 6.70321 7.09999 7.19958L7.09999 11.4997C7.09999 11.996 6.69704 12.3984 6.19999 12.3984ZM8.89996 11.4997C8.89996 11.996 9.30291 12.3984 9.79996 12.3984C10.297 12.3984 10.7 11.996 10.7 11.4997V7.19563C10.7 6.69926 10.297 6.29688 9.79996 6.29688C9.30291 6.29688 8.89996 6.69926 8.89996 7.19563V11.4997Z"
        fill="currentcolor"
      />
    </svg>
  );
};
