import React, { SVGProps } from 'react';

export const Chest = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 28 28" fill="none">
      <path
        d="M6.3 1.75C2.82061 1.75 0 4.57061 0 8.05V10.675H8.75C8.77498 10.6743 8.80005 10.675 8.8252 10.675C10.0975 10.675 11.1584 11.5735 11.399 12.775C11.573 14.0561 12.6712 15.05 14 15.05C15.3051 15.05 16.3877 14.0227 16.5908 12.775H16.5951C16.8085 11.5397 17.8854 10.5 19.1816 10.5C19.1893 10.5 19.1969 10.5001 19.2045 10.5C19.2197 10.4999 19.2349 10.4996 19.25 10.5H28V8.05C28 4.57061 25.1794 1.75 21.7 1.75H6.3Z"
        fill="currentColor"
      />
      <path
        d="M7.71203 13.65H0V23.1C0 24.8397 1.4103 26.25 3.15 26.25H24.85C26.5897 26.25 28 24.8397 28 23.1V13.65H20.288C19.8862 13.65 19.5426 13.9219 19.3997 14.2975C18.5721 16.4738 16.4667 18.025 14 18.025C11.5334 18.025 9.4279 16.4738 8.6003 14.2975C8.45748 13.9219 8.11385 13.65 7.71203 13.65Z"
        fill="currentColor"
      />
    </svg>
  );
};
