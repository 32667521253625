import React, { SVGProps } from 'react';

export const ArrowLongRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M7.99644 3.26582C8.34791 2.91436 8.91776 2.91436 9.26923 3.26584L13.2962 7.2929C13.6867 7.68343 13.6867 8.31659 13.2962 8.70711L9.26732 12.736C8.91585 13.0874 8.346 13.0874 7.99453 12.736C7.64305 12.3845 7.64306 11.8146 7.99453 11.4632L10.5577 8.9H2.89878C2.40172 8.9 1.99878 8.49706 1.99878 8C1.99878 7.50294 2.40172 7.1 2.89878 7.1H10.5577L7.99642 4.53862C7.64495 4.18714 7.64496 3.61729 7.99644 3.26582Z"
        fill="currentcolor"
      />
    </svg>
  );
};
